import React from "react"
import { Layout } from "../components"

const TermsOfService = () => (
  <Layout>
    <div className="p-10">
      <h1 className="text-h1-mobile sm:text-h1-desktop font-cambon sm:mb-20">
        Allgemeine Geschäftsbedingungen NFT Shop Builder (AGB)
      </h1>

      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        Geltungsbereich
      </h2>

      <p className="my-3">
        Die nachstehenden Allgemeinen Geschäftsbedingungen gelten für alle
        Leistungen der Gorilla Funds UG, Hermann-Wellensiek-Str. 33, 67346
        Speyer (nachfolgend „wir“) gegenüber Unternehmen im Sinne von § 14 BGB
        (nachfolgend „Sie“ oder „Kunde“). Ihre eigenen Allgemeinen
        Geschäftsbedingungen werden nicht Vertragsbestandteil, auch wenn wir
        diesen nicht noch einmal gesondert widersprechen. Vertragsgegenstand und
        Funktionsweise Der NFT Shop Builder (nachfolgend auch „Software“) ist
        eine über das Internet abrufbare und browserbasierte
        Verkaufs-Visualisierungs-Software, mit der ein Online-Shop mit
        individuellen Verkaufs-Seiten erstellt werden kann. Die erstellten
        Shop-Seiten dienen dazu, Daten zu Art und Preis der angebotenen NFTs
        grafisch darzustellen und letztlich den digitalen Erwerb des NFTs zu
        ermöglichen. Welche NFTs später über den erstellten Shop verkauft
        werden, kann der Nutzer frei wählen. Diese Entscheidung obliegt ihm
        allein. Um weitere Funktionen der Software wie das Kaufen und Einlösen
        von NFTs innerhalb des erstellten Shops nutzen zu können, ist die
        Verknüpfung bzw. Einbindung einer Wallet erforderlich. Über den mit dem
        NFT Shop Builder erstellten Online-Shop können alle im Angebot
        befindlichen Non-Fungible Token (kurz: „NFT“) durch den Interessenten
        (nachfolgend auch „Kunde“) geprüft und über die verknüpften Wallets
        kundeneigene Käufe getätigt werden, wobei wir zu keinem Zeitpunkt mit
        der oder den Wallets des Kunden interagieren oder Vermögens- oder
        Kryptowerte (gemeinsam „Vermögenswerte“) für oder von Kunden halten. Der
        Kunde allein ist und bleibt in der Verfügungsgewalt über seine
        Vermögenswerte, die über die Software gekauft werden. Zur Sicherheit für
        den Kunden werden dessen Eingaben auf Richtigkeit insoweit geprüft, dass
        die Eingabe des Kunden beim Kauf mit dem dargestellten Ergebnis in der
        verknüpften Wallet vor dem Absenden einer Transaktion übereinstimmt.
        Dies wird dem Kunden auch noch einmal vor dem Absenden gesondert
        visualisiert dargestellt. Die Verkaufs-Seiten dienen lediglich der
        Visualisierung von Daten und der Verknüpfung mit anderen Onlinediensten
        und basieren auf Sanity sowie weiterer Open Source Software. Der NFT
        Shop Builder wird für einen Zeitraum von zwei Jahren ab Kauf des NFTs
        aus der MUZZ & the Banana Frat Club Kollektion zur Nutzung überlassen.
        Für die Beschaffenheit ist die online einsehbare Beschreibung
        abschließend maßgeblich. Eine darüber hinaus gehende Beschaffenheit
        schulden wir nicht. Wir behalten uns das Recht vor, Leistungen oder
        Funktionen im NFT Shop Builder zu erweitern, zu ändern, zu löschen und
        Verbesserungen vorzunehmen, insbesondere, wenn diese dem technischen
        Fortschritt dienen, notwendig erscheinen oder um Missbrauch zu
        verhindern. Wir werden diese Änderungen nur vornehmen, wenn diese für
        Sie zumutbar sind oder wir aufgrund gesetzlicher Vorschriften hierzu
        verpflichtet sind.
      </p>

      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        Zustandekommen des Vertrags
      </h2>
      <p className="my-3">
        Der Vertrag zwischen uns und Ihnen kommt mit dem Kauf eines NFTs aus der
        Kollektion MUZZ & the Bananafratclub online zustande. Die Darstellung
        der Software im Internet stellt kein rechtlich bindendes Angebot
        unsererseits dar.
      </p>
      <p className="my-3">
        Ein Vertrag über die Nutzung der Software kommt erst mit der Akzeptanz
        der Geschäftsbedingungen zustande. Der Vertragstext einschließlich der
        im Zeitpunkt des Vertragsschlusses gültigen AGB wird von uns nicht
        gespeichert und ist Ihnen daher in dieser Fassung nach Vertragsschluss
        nicht mehr zugänglich. Sie haben jedoch die Möglichkeit, die AGB im
        Rahmen des Registrierungsprozesses abzurufen und in wiedergabefähiger
        Form zu speichern.
      </p>

      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        NFT Shop Builder{" "}
      </h2>

      <p className="my-3">
        Im Rahmen des Aufsetzens der NFT -Shop-Page erhalten Sie Zugangsdaten zu
        Ihrer persönlichen Sanity-Instanz zur Administration der Inhalte, die in
        Ihrem Online-Shop und den jeweiligen Verkaufs-Seiten angezeigt werden.
        Auch hier gilt, dass Sie dazu verpflichtet sind Ihre Zugangsdaten geheim
        zu halten. Wenn Sie Grund zu der Annahme haben, dass unbefugte Dritte
        von Ihren Zugangsdaten Kenntnis erlangt haben oder erlangen können, sind
        wir unverzüglich zu informieren. Sie sind in diesem Fall zudem
        verpflichtet, Ihr Passwort unverzüglich zu ändern. Sie sind für eine
        angemessene Internetverbindung sowie für die erforderlichen
        Systemvoraussetzungen für die Nutzung der Software verantwortlich.
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        Verkaufs-Seiten
      </h2>

      <p className="my-3">
        Wir sind nicht verantwortlich für die auf den Verkaufs-Seiten
        dargestellten Inhalte. Wir sind keine Vertragspartei bei
        Kommunikationen, Transaktionen, Interaktionen oder Streitigkeiten
        zwischen Ihnen und dem Käufer der dargestellten Inhalte und übernehmen
        keine Verantwortung oder Haftung dafür. Die von Ihnen erstellte
        Verkaufs-Seite wird unter einer von Ihnen gewählten oder durch den NFT
        Shop Builder generierten Subdomain über Dritte veröffentlicht. Für mehr
        Informationen dazu, welche personenbezogenen Daten wir auf welche Weise
        verarbeiten, verweisen wir auf unsere Datenschutzhinweise. Sie sind
        nicht dazu berechtigt, eine extern gehostete Domain auf eine
        Verkaufs-Seite weiterzuleiten oder die Verkaufs-Seite in einer externen
        Webseite anzuzeigen (z. B. Einbindung per Frame oder iFrame). Wir können
        zur Erweiterung der Leistungspakete Dienste von Drittanbietern über den
        NFT Shop Builder anbieten, die auf der Verkaufs-Seite integriert werden
        können. Art und Umfang der Inanspruchnahme richten sich nach den
        jeweiligen AGB der Drittanbieter, auf die wir gesondert hinweisen.
      </p>

      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">Ihre Pflichten</h2>
      <p className="my-3">
        Für sämtliche Inhalte, die Sie im selbst erstellten Online-Shop und auf
        den Verkauf-Seiten speichern und zum Abruf bereithalten, sind Sie
        verantwortlich. Wir sind nicht verpflichtet, den erstellten Online-Shop
        sowie die jeweiligen Verkaufs-Seiten auf eventuelle Rechtsverstöße zu
        prüfen. Die Einhaltung der gesetzlichen Vorgaben in Ihrem erstellten
        Online-Shop und auf Ihren Verkaufs-Seiten liegt ebenfalls in Ihrem
        Verantwortungsbereich. Dies gilt insbesondere (aber nicht abschließend)
        für das Datenschutzrecht, Wettbewerbsrecht, Urheberrecht, Markenrecht,
        Fernabsatzrecht und Telemedienrecht. Sie verpflichten sich, der
        Impressumspflicht (oder der jeweils im Land der Nutzung entsprechend
        gesetzlichen Pflicht) nachzukommen. Das ist mit Beginn der Abrufbarkeit
        des Online-Shops und der Verkaufs-Seite für Dritte vorzuhalten. Wir
        erstellen regelmäßig Sicherheitskopien (Backups) unserer Server.
        Einzelne Verkaufs-Seiten und / oder Inhalte können aus diesen Backups
        allerdings nicht wiederhergestellt werden. Die Speicherung und Sicherung
        von Daten bzw. Inhalten Ihres Online-Shops und Ihrer Verkaufs-Seiten
        liegt in Ihrem Verantwortungsbereich. Sie dürfen die im NFT Shop Builder
        zur Verfügung stehenden Designs nur für die die Erstellung Ihres
        Online-Shops und der Verkaufs-Seiten nutzen. Es ist ausdrücklich
        untersagt, den Online-Shop sowie die Verkaufs-Seiten auf fremden
        IT-Systemen zu speichern und auf einem fremden Server zu nutzen. Sie
        dürfen die Inhalte unserer Designvorlagen (z. B. Bilder, Texte, Layout,
        Schriftarten) ausschließlich in Ihrem bei uns erstellten Online-Shop und
        auf Ihren einzelnen Verkaufs-Seiten verwenden. Eine Veröffentlichung
        dieser Inhalte an anderer Stelle (z. B. in Printmedien oder anderen
        Plattformen im Internet) ist nicht gestattet.
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">Verfügbarkeit</h2>
      <p className="my-3">
        Wir schulden ein Bemühen, dass die mit dem NFT Shop Builder erstellten
        Online-Shops samt Verkaufs-Seiten über das von uns unterhaltene Netz und
        das daran angeschlossene Internet rund um die Uhr weltweit abrufbar
        sind. Wir übernehmen keine Verantwortung für den Erfolg des jeweiligen
        Zugangs, soweit nicht ausschließlich das von uns betriebene Netz
        einschließlich der Schnittstellen zu Netzen Dritter benutzt wird. Der
        NFT Shop Builder steht Ihnen an sieben (7) Tagen der Woche jeweils 24
        Stunden zur Verfügung. Die durchschnittliche Verfügbarkeit beträgt 99,00
        % im Jahresmittel abzüglich der gegebenenfalls für das Einspielen von
        Updates, Upgrades, neuen Releases und sonstigen Modifikationen und
        Wartungsarbeiten notwendigen Zeit. Ist die Sicherheit des Netzbetriebs
        oder die Aufrechterhaltung der Netzintegrität gefährdet, kann der Zugang
        zur Software vorübergehend beschränkt werden. Die Verfügbarkeit kann in
        der Phase des Beta-Launches geringer sein. Hier kann es zu geringerer
        Verfügbarkeit kommen und Funktionseinschränkungen sind zu erwarten. In
        diesem Zeitraum gilt unsere Software als experimentelle Software. Erst
        nach Abschluss der Beta-Phase garantieren wir die oben genannte
        Verfügbarkeit. In dieser Phase erfolgt die Nutzung auf eigenes Risiko.
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        Elektronische Kommunikation
      </h2>
      <p className="my-3">
        Sie stimmen zu, für vertragliche Zwecke (z. B. Rechnungen, wichtige
        vertragliche Informationen oder maßgebliche technische Änderungen)
        ausschließlich elektronische Nachrichten von uns zu erhalten. Es ist
        daher wichtig, dass Ihre Daten auf dem aktuellen Stand sind. Sie können
        Ihre Zustimmung zur Kommunikation ausschließlich auf elektronischem Wege
        durch Mitteilung an uns jederzeit für die Zukunft widerrufen. Für diesen
        Fall behalten wir uns jedoch das Recht vor, Ihren Account zu schließen.
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">Gewährleistung</h2>
      <p className="my-3">
        Wir bemühen uns um eine möglichst unterbrechungsfreie Nutzbarkeit der
        Software und darauf basierenden Online-Shops mit den einzelnen
        Verkaufs-Seiten. Sie erkennen jedoch an, dass aus technischen Gründen
        und aufgrund äußerer Einflüsse (z. B. Nichtverfügbarkeit der
        Kommunikationsnetze, Unterbrechung der Stromversorgung, Hardware- und
        Softwarefehler) eine ununterbrochene Verfügbarkeit nicht realisierbar
        ist. Ein Anspruch auf Hochverfügbarkeit besteht nicht. Vorübergehende
        Zugriffsbeschränkungen begründen weder Gewährleistungsansprüche noch ein
        Recht zur außerordentlichen Kündigung. Die im Online-Shop sowie auf den
        Verkaufs-Seiten angezeigten Inhalte stammen nicht von uns. Für die
        Richtigkeit, Vollständigkeit und Aktualität übernehmen wir daher keine
        Gewähr. Sie sind verpflichtet, auftretende Fehler und Probleme bei der
        Verwendung der Software so genau wie möglich zu beschreiben. Sie haben
        uns die Möglichkeit zur Überprüfung und Fehlerbehebung zu geben, auch
        wenn hierdurch die Nutzung der Software zeitweise nur eingeschränkt
        möglich ist. Wir leisten bei Sachmängeln zunächst Gewähr durch
        Nacherfüllung. Hierzu überlassen wir Ihnen neue und mangelfeie Software
        oder beseitigen den Mangel. Als Mangelbeseitigung gilt auch, dass wir
        Ihnen zumutbare Möglichkeiten aufzeigen, die Auswirkungen des Mangels zu
        vermeiden. Bei Rechtsmängeln leisten wir zunächst Gewähr durch
        Nacherfüllung. Hierzu verschaffen wir Ihnen eine rechtlich einwandfreie
        Benutzungsmöglichkeit an der Software oder an ausgetauschter oder
        geänderter gleichwertiger Software. § 536a Abs. 2 BGB wird
        ausgeschlossen (Aufwendungsersatzanspruch bei Selbstbeseitigung des
        Mangels). Wir sind berechtigt, die Nacherfüllung davon abhängig zu
        machen, dass Sie zumindest einen angemessenen Teil der Vergütung bezahlt
        haben. Erbringen wir Leistungen bei der Fehlersuche oder -beseitigung,
        ohne hierzu verpflichtet zu sein, können wir hierfür eine Vergütung
        verlangen. Das gilt insbesondere, wenn ein Mangel nicht nachweisbar oder
        uns nicht zuzurechnen ist. Behaupten Dritte Ansprüche, die Sie daran
        hindern, die Ihnen vertraglich eingeräumten Nutzungsrechte wahrzunehmen,
        unterrichten Sie uns unverzüglich und umfassend. Sie ermächtigen uns
        hiermit, Klagen gegen Dritte gerichtlich und außergerichtlich allein zu
        führen. Werden Sie verklagt, stimmen Sie sich mit uns ab und nehmen
        Prozesshandlungen, insbesondere Anerkenntnisse und Vergleiche, nur mit
        unserer vorherigen Zustimmung vor. Die Verjährungsfrist für alle
        Gewährleistungsansprüche beträgt ein (1) Jahr und beginnt mit der
        Überlassung der Software. Bei Vorsatz oder grober Fahrlässigkeit, bei
        arglistigem Verschweigen eines Mangels, bei Personenschäden oder
        Rechtsmängeln, bei Ansprüchen nach dem Produkthaftungsgesetz sowie bei
        Garantien (§ 444 BGB) gelten die gesetzlichen Verjährungsfristen. Es
        gilt die Gewähr für das Land, in dem wir unseren Geschäftssitz haben.
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        Eingabe und Übertragung von Daten
      </h2>
      <p className="my-3">
        Der erstellte Online-Shop und die einzelnen Verkaufs-Seiten dienen der
        Visualisierung von Daten, die von Ihnen selbst stammen. Sie geben Ihre
        Daten über den NFT Shop Builder bei Erstellung des Online-Shops und der
        betreffenden Verkaufs-Seite ein. Bei der Eingabe haben Sie die
        erforderliche Sorgfalt anzuwenden. Der NFT Shop Builder basiert auf Open
        Source Software. Sie erkennen an, dass eine vollständig sichere
        Übertragung von Daten nicht geschuldet ist. Es besteht die Möglichkeit,
        dass Unbefugte die Datenübertragung beeinflussen. Sie sind daher
        verpflichtet, die visualisieren Angaben gegebenenfalls auf ihre
        Richtigkeit hin zu überprüfen.
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        Preise und Zahlung
      </h2>
      <p className="my-3">
        Die genannten Preise sind Festpreise in der Cryptocurrency Near. Soweit
        nicht die Hauptleistungspflicht, d.h. die Pflicht zur Zahlung der
        nutzungsunabhängigen Vergütung betroffen ist, bestimmen wir die
        Vergütung durch die jeweils aktuelle Preisliste nach billigem Ermessen.
        Wir sind berechtigt, die Preise zum Beginn der nächsten Vertragslaufzeit
        mit einer angemessenen Ankündigungsfrist von mindestens einem (1) Monat
        zu ändern. Widersprechen Sie der Änderung nicht binnen einer von uns
        gesetzten angemessenen Frist, gilt die Änderung als genehmigt. Wir
        weisen Sie in der Änderungsmitteilung darauf hin, dass die Änderung
        wirksam wird, wenn Sie nicht widersprechen.
      </p>

      <p className="my-3">
        Ändern sich zu einem Zeitpunkt innerhalb des Abrechnungszeitraums die
        Preise oder deren Bestandteile (z.B. Erhöhung der Umsatzsteuer), erfolgt
        eine separate Abrechnung des Leistungszeitraums vom Beginn des
        Abrechnungszeitraums bis zum Änderungszeitpunkt und des
        Leistungszeitraums vom Änderungszeitpunkt bis zum Ende des
        Abrechnungszeitraums. Gegen unsere Forderungen können Sie nur mit
        unwidersprochenen oder rechtskräftig festgestellten Forderungen
        aufrechnen.
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">Urheberrecht</h2>
      <p className="my-3">
        Wir räumen Ihnen ein auf die Dauer der Vertragslaufzeit befristetes,
        einfaches und nicht übertragbares Recht ein, den NFT Shop Builder zu
        nutzen. Es gelten ergänzend die Lizenzbestimmungen von Sanity. Entstehen
        während der Vertragslaufzeit Datenbanken, insbesondere durch
        Zusammenstellung von Anwendungsdaten durch Tätigkeiten, die nach diesen
        AGB erlaubt sind, so stehen alle Rechte hieran Ihnen zu.
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">Haftung</h2>
      <p className="my-3">
        Unsere Haftung, gleich aus welchem Rechtsgrund, ist auf den
        vertragstypisch vorhersehbaren Schaden beschränkt. Dies gilt nicht für
        die Verletzung von vertragswesentlichen Pflichten, d.h. vertragliche
        Verpflichtungen, deren Erfüllung die ordnungsgemäße Durchführung des
        Vertrags überhaupt erst ermöglicht und auf deren Einhaltung Sie
        regelmäßig vertrauen und vertrauen dürfen (Kardinalspflichten), für
        Schäden an Körper, Leben und Gesundheit, für vorsätzliche oder grob
        fahrlässige Pflichtverletzungen, für die Haftung nach dem
        Produkthaftungsgesetz oder für Ansprüche aus Garantien. Wir haften nicht
        für Schäden, die durch den Einsatz der Dienste von Drittanbietern
        entstehen. Vor der Einbindung / Verknüpfung dieser Dienste von
        Drittanbietern sollten Sie stets die AGB des betreffenden Drittanbieters
        prüfen. Die Nutzung der Dienste von Drittanbietern erfolgt auf eigenes
        Risiko.
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        Schlussvorschriften
      </h2>
      <p className="my-3">
        Sollte eine Bestimmung dieser AGB unwirksam sein oder werden, eine
        unzulässige Fristbestimmung oder eine Lücke enthalten, so bleibt die
        Rechtswirksamkeit der übrigen Bestimmungen hiervon unberührt. Soweit die
        Unwirksamkeit sich nicht aus einem Verstoß gegen §§ 305 ff. BGB ergibt,
        gilt anstelle der unwirksamen Bestimmung eine wirksame Bestimmung als
        vereinbart, die dem von den Vertragspartnern Gewollten wirtschaftlich am
        nächsten kommt. Das Gleiche gilt für den Fall einer Lücke. Im Falle
        einer unzulässigen Frist gilt das gesetzlich zulässige Maß.
      </p>

      <h1 className="text-h1-mobile sm:text-h1-desktop font-cambon sm:m-20">
        General Terms and Conditions NFT Shop Builder (GTC)
      </h1>

      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">Scope</h2>

      <p className="my-3">
        The following general terms and conditions apply to all services
        provided by Gorilla Funds UG, Hermann-Wellensiek-Str. 33, 67346 Speyer
        (hereinafter &quot;we&quot;) to companies within the meaning of § 14 BGB
        (hereinafter &quot;you&quot; or &quot;customer&quot;). Your own general
        terms and conditions will not become part of the contract, even if we do
        object to them separately. The subject of the contract and functioning
        The NFT Shop Builder (hereinafter also referred to as
        &quot;software&quot;) is browser-based sales visualization software that
        can be called up via the Internet and can be used to create an online
        shop with individual sales pages. The shop pages created are used to
        graphically display data on the type and price of the NFTs offered and
        ultimately to enable the NFT purchased digitally. The user can freely
        choose which NFTs are later to be sold via the created shop. This
        decision is his alone. In order to be able to use other software
        functions such as buying and redeeming NFTs within the created shop, a
        wallet must be linked or integrated. Via the online shop created with
        the NFT Shop Builder, all non-fungible tokens (&quot;NFT&quot; for
        short) on offer can be checked by the interested party (hereinafter also
        &quot;customer&quot;), and customer-specific purchases can be made via
        the linked wallets, whereby we never interact with customer&lsquo;s
        wallet(s) or hold any assets or crypto-assets (collectively
        &quot;Assets&quot;) for or from Customers. The customer has and will
        have sole control of its assets purchased through the software. For the
        security of the customer, their entries are checked for correctness to
        the extent that the customer&lsquo;s entry during the purchase matches
        the displayed result in the linked wallet before sending a transaction.
        This is also visualized separately for the customer before sending. The
        sales pages are only used to visualize data and link to other online
        services and are based on Sanity and other open-source software. The NFT
        Shop Builder is available for use for a period of two years from the
        purchase of the NFT from the MUZZ & the Banana Frat Club collection. The
        description that can be viewed online is definitive in the quality. We
        do not owe any further quality. We reserve the right to expand, change
        or delete services or functions in the NFT Shop Builder and to make
        improvements, especially if these serve technical progress, appear
        necessary, or prevent misuse. We will only make these changes if they
        are reasonable for you or if we are obliged to do so by law.
      </p>

      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        Formation of the contract
      </h2>

      <p className="my-3">
        The contract between you and us comes about with the purchase of an NFT
        from the MUZZ & the Bananafratclub collection online. The presentation
        of the software on the Internet does not represent a legally binding
        offer on our part.
      </p>
      <p className="my-3">
        A contract for the use of the software is only concluded with the
        acceptance of the terms and conditions. The text of the contract,
        including the General Terms and Conditions valid at the time the
        contract was concluded, is not stored by us and is therefore no longer
        accessible to you in this version after the contract has been concluded.
        However, you have the option of accessing the General Terms and
        Conditions as part of the registration process and saving them in a
        reproducible form.
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        NFT Shop Builder
      </h2>

      <p className="my-3">
        As part of setting up the NFT shop page, you will receive access data
        for your personal sanity instance for the administration of the content
        that is displayed in your online shop and the respective sales pages.
        Here, too, you are obliged to keep your access data secret. If you have
        reason to believe that unauthorized third parties have or may have
        knowledge of your access data, we must be informed immediately. In this
        case, you are also obliged to change your password immediately. You are
        responsible for an adequate internet connection and for the necessary
        system requirements for the use of the software.
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">Sales Pages</h2>

      <p className="my-3">
        We are not responsible for the content presented on the sales pages. We
        are not a party to and assume no responsibility or liability for any
        communication, transaction, interaction, or dispute between you and the
        purchaser of the content featured. The sales page you create will be
        published under a subdomain of your choice or generated by the NFT Shop
        Builder via a third party. For more information about which personal
        data we process and how we refer to our data protection information. You
        are not authorized to do so, to forward an externally hosted domain to a
        sales page, or to display the sales page on an external website (e.g.,
        integration via frame or iframe). We may offer third-party services via
        the NFT Shop Builder to extend the service packages, which can be
        integrated on the sales page. The type and scope of the claim are based
        on the respective terms and conditions of the third party, to which we
        refer separately.
      </p>

      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">Your duties</h2>

      <p className="my-3">
        You are responsible for all content that you store and make available
        for retrieval in the online shop you have created yourself and on the
        sales pages. We are not obliged to check the online shop created and the
        respective sales pages for possible legal violations. You are also
        responsible for complying with the legal requirements in your online
        shop and on your sales pages. This applies in particular (but not
        exclusively) to data protection law, competition law, copyright law,
        trademark law, distance selling law, and Telemedia law. You undertake to
        comply with the imprint obligation (or the corresponding legal
        obligation in the country of use). This must be made available to third
        parties once the online shop and the sales site can be called up. We
        regularly create security copies (backups) of our servers. However,
        individual sales pages and/or content cannot be restored from these
        backups. You are responsible for storing and backing up the data or
        content of your online shop and your sales pages. You may only use the
        designs available in the NFT Shop Builder to create your online shop and
        sales pages. It is expressly forbidden to save the online shop and the
        sales pages on third-party IT systems and to use them on a third-party
        server. You may only use the content of our design templates (e.g.,
        images, text, layout, fonts) in your online shop created by us and on
        your individual sales pages. Publication of this content elsewhere
        (e.g., in print media or other platforms on the Internet) is not
        permitted.
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">Availability</h2>

      <p className="my-3">
        We owe an effort to ensure that the online shops created with the NFT
        Shop Builder, including sales pages, can be accessed around the clock
        worldwide via the network we maintain and the Internet-connected to it.
        We assume no responsibility for the success of the respective access
        unless the network operated by us, including the interfaces to
        third-party networks, is used exclusively. The NFT Shop Builder is
        available to you 24 hours a day, seven (7) days a week. The average
        availability is 99.00% on average over the year minus the time required
        to import updates, upgrades, new releases, and other modifications and
        maintenance work. If the security of network operation or the
        maintenance of network integrity is at risk, access to the software can
        be temporarily restricted. Availability may be lower during the beta
        launch phase. There may be less availability here, and functional
        restrictions are to be expected. During this period, our software is
        considered experimental software. We only guarantee the availability
        mentioned above after the end of the beta phase. In this phase, you use
        it at your own risk.
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        Electronic communication
      </h2>

      <p className="my-3">
        You agree to only receive electronic messages from us for contractual
        purposes (e.g., invoices, important contractual information, or relevant
        technical changes). It is therefore important that your data is up to
        date. You can revoke your consent to communicate exclusively by
        electronic means by notifying us at any time in the future. In this
        case, however, we reserve the right to close your account.
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">Warranty</h2>
      <p className="my-3">
        We strive to ensure that the software and the online shops based on it
        with the individual sales pages can be used without interruption.
        However, you acknowledge that uninterrupted availability is not feasible
        for technical reasons and due to external influences (e.g.,
        unavailability of the communication networks, interruption of the power
        supply, hardware and software errors). There is no entitlement to high
        availability. Temporary access restrictions do not justify warranty
        claims or a right to extraordinary termination. The content displayed in
        the online shop and on the sales pages does not come from us. We,
        therefore, assume no liability for the correctness, completeness, and
        topicality. You are required to describe any errors and problems
        encountered while using the software as accurately as possible. You have
        the opportunity to practice testing and troubleshooting, even if this
        means that the use of the software is temporarily only possible to a
        limited extent. In the event of material defects, we initially provide a
        guarantee through supplementary performance. For this, we provide you
        with new and defect-free software or eliminate the defect. The
        elimination of defects also means that we show you reasonable options
        for avoiding the effects of the defect. In the event of defects in the
        title, we initially provide a guarantee through supplementary
        performance. For this purpose, we provide you with a legally flawless
        opportunity to use the software or exchange or modify equivalent
        software. Section 536a, paragraph 2 of the German Civil Code is excluded
        (claim for reimbursement of expenses if the defect is rectified by
        oneself). We are entitled to make subsequent performance dependent on
        you having paid at least a reasonable part of the remuneration. If we
        provide services for troubleshooting or troubleshooting without being
        obliged to do so, we can demand payment for this. This applies in
        particular if a defect cannot be proven or cannot be attributed to us.
        If third parties assert claims that prevent you from exercising the
        rights of use granted to you under the contract, please inform us
        immediately and comprehensively. You hereby authorize us to take legal
        action against third parties in and out of court alone. If you are sued,
        coordinate with us and take legal action, in particular acknowledgments
        and settlements, only with our prior consent. The limitation period for
        all warranty claims is one (1) year and begins with the transfer of the
        software. In the case of intent or gross negligence, fraudulent
        concealment of a defect, personal injury or defects of title, claims
        under the Product Liability Act, and guarantees (§ 444 BGB), the
        statutory limitation periods apply. The warranty applies to the country
        in which we have our place of business.
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        Entry and transmission of data
      </h2>
      <p className="my-3">
        The online shop created and the individual sales pages are used to
        visualize data that you have provided yourself. You enter your data via
        the NFT Shop Builder when creating the online shop and the relevant
        sales page. You must exercise the necessary care when entering it. The
        NFT Shop Builder is based on open-source software. You acknowledge that
        a completely secure transmission of data is not owed. There is a
        possibility of unauthorized persons influencing the data transmission.
        You are therefore obliged to check the visualized information for
        accuracy if necessary.
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        Prices and Payment
      </h2>

      <p className="my-3">
        The prices mentioned are fixed prices in the Cryptocurrency Near. Unless
        the main obligation to perform, i.e., the obligation to pay the
        usage-independent remuneration, is affected, we determine the
        remuneration based on the current price list at our reasonable
        discretion. We are entitled to change the prices at the beginning of the
        next contract period with a reasonable notice period of at least one (1)
        month. If you do not object to the change within a reasonable period set
        by us, the change is deemed to have been approved. In the change
        notification, we will point out that the change will take effect if you
        do not object.
      </p>
      <p className="my-3">
        If the prices or their components change at a point in time within the
        billing period (e.g., an increase in sales tax), the service period is
        billed separately from the beginning of the billing period to the time
        of the change and for the service period from the time of the change to
        the end of the billing period. You can only offset our claims with
        uncontradicted or legally established claims.
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">Copyright</h2>

      <p className="my-3">
        We grant you a limited, non-exclusive, and non-transferable right to use
        the NFT Shop Builder for the duration of the contract period. The
        license terms of Sanity also apply. If databases are created during the
        contract period, in particular through the compilation of application
        data through activities that are permitted under these General Terms and
        Conditions, you are entitled to all rights to them.
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">Liability</h2>
      <p className="my-3">
        Our liability, for whatever legal reason, is limited to the foreseeable
        damage that is typical for the contract. This does not apply to the
        breach of essential contractual obligations, i.e., contractual
        obligations, the fulfillment of which is essential for the proper
        execution of the contract and on the observance of which you regularly
        rely upon and may rely (cardinal obligations) on, for damage to the
        body, life, and health, for intentional or grossly negligent breaches of
        duty, for liability under the Product Liability Act or for claims
        arising from guarantees.
      </p>
      <p className="my-3">
        We are not liable for any damage resulting from the use of third-party
        services. Before integrating/linking these third-party services, you
        should always read the AGB check third-party providers concerned. Use of
        third-party services is at your own risk.
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        Final Provisions
      </h2>

      <p className="my-3">
        Should a provision of these General Terms and Conditions be or become
        invalid, contain an inadmissible deadline or a gap, the legal validity
        of the remaining provisions shall remain unaffected insofar as the
        ineffectiveness does not result from a violation of §§ 305 et seq. BGB,
        an effective provision shall be deemed to have been agreed in place of
        the ineffective provision, which comes as close as possible to what the
        contracting parties want from an economic point of view. The same
        applies in the event of a gap. In the event of an inadmissible period,
        the legally permissible extent applies.
      </p>
    </div>
  </Layout>
)

export default TermsOfService
